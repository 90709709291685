import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'
import user from './user/reducers'
import menu from './menu/reducers'
import settings from './settings/reducers'
import virtualSettings from './virtual/settings/reducers'
import virtualMenu from './virtual/menu/reducers'
import virtualSetup from './virtual/setup/reducers'
import stallDesign from './virtual/stalldesign/reducers'
import virtualEvent from './virtual/event/reducers'
import virtualUser from './virtual/user/reducers'
import registerVirtualUser from './virtual/registerUser/reducers'
import Chat from './virtual/chat/reducers'
import agenda from './virtual/agenda/reducers'
import streamFeature from './virtual/streamfeature/reducers'
import meetings from './virtual/meetings/reducers'
import photobooth from './virtual/photobooth/reducers'
import aiphotobooth from './virtual/aiphotobooth/reducers'
import toonphotobooth from './virtual/toonphotobooth/reducers'
import payment from './virtual/payment/reducers'
import analytics from './virtual/analytics/reducers'
import directory from './virtual/directory/reducers'
import stallConfigurator from './virtual/stallconfigurator/reducers'
import pointredeem from './virtual/pointredeem/reducers'
import contest from './virtual/contest/reducers'
import reactions from './virtual/reactions/reducers'
import messagebox from './virtual/messagebox/reducers'
import eshop from './virtual/eshop/reducers'

export default history =>
  combineReducers({
    router: connectRouter(history),
    user,
    menu,
    settings,
    virtualSettings,
    virtualMenu,
    virtualSetup,
    stallDesign,
    virtualEvent,
    virtualUser,
    registerVirtualUser,
    Chat,
    agenda,
    streamFeature,
    meetings,
    photobooth,
    aiphotobooth,
    payment,
    analytics,
    directory,
    stallConfigurator,
    pointredeem,
    contest,
    toonphotobooth,
    reactions,
    messagebox,
    eshop,
  })
