export async function getLeftMenuData() {
  return [
    {
      title: 'Settings',
      key: 'settings',
      icon: 'icmn icmn-cog utils__spin-delayed--pseudo-selector',
    },
    {
      divider: true,
    },
    {
      title: 'Dashboard',
      key: 'dashboardAlpha',
      url: '/dashboard/alpha',
      icon: 'icmn icmn-home',
    },
    {
      title: 'Virtual Events',
      key: 'virtualEvents',
      icon: 'icmn icmn-podcast',
      children: [
        {
          key: 'addEvent',
          title: 'Add Events',
          url: '/dashboard/add-event',
          pro: false,
        },
        {
          key: 'setupEvents',
          title: 'Setup Events',
          url: '/dashboard/setup-event',
          pro: false,
        },
        {
          key: 'addAgenda',
          title: 'Add Agenda',
          url: '/dashboard/add-agenda',
          pro: false,
        },
        {
          key: 'sendNotification',
          title: 'Live Notification',
          url: '/dashboard/live-notification',
          pro: false,
        },
        {
          key: 'menuDesigner',
          title: 'Menu Designer',
          url: '/dashboard/menu-designer',
          pro: false,
        },
        {
          key: 'speaker',
          title: 'Add Speaker',
          url: '/dashboard/add-speaker',
          pro: false,
        },
        {
          key: 'stalldesigner',
          title: 'Stall Designer',
          url: '/dashboard/stall-designer',
          pro: true,
        },
      ],
    },
    {
      title: 'Analytics',
      key: 'analytics',
      url: '/dashboard/analytics',
      icon: 'icmn icmn-file-text',
      pro: true,
    },
  ]
}
export async function getTopMenuData() {
  return [
    {
      title: 'Settings',
      key: 'settings',
      icon: 'icmn icmn-cog utils__spin-delayed--pseudo-selector',
    },
  ]
}
