import actions from './actions'

const initialState = {
  isPhotoboothOpen: false,
  currentStep: 0,
  imageSrc: '',
  webcamLoading: true,
  imageString: ''
}

export default function userReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload }
    default:
      return state
  }
}
